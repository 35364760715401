import { Injectable, HostListener, Renderer2, Inject, RendererFactory2, PLATFORM_ID, NgZone, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, Location, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BehaviorSubject, timer } from 'rxjs';
import { DataApiService } from '@app/core/services/http/data-api.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Meta, Title } from '@angular/platform-browser';
import { AuthService } from '@app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertModalComponent } from '@app/alert/alert-modal/alert-modal.component';
import { CartService } from "../../shared/service/cart.service";
import { LoginComponent } from '@app/misc/login/login/login.component';
import { UploaderService } from '@app/core/services/uploader.service';
import { UtilityService } from '@app/core/services/utility.service';
import { GoogleAnalyticsService } from './google-analytics.service';
import { MetaService2 } from './meta.service';
import { environment } from 'environments/environment';
declare let HawkSearch;

interface InventoryStock {
  count: number;
  data: any[];
  total_discount: number;
  total_offer_amount: number;
  totalamount: number;
  total_tax: number;
  store: number
}

@Injectable({
  providedIn: 'root',
})

export class CommonService {
  private renderer: Renderer2;
  globalRouterLoaderShow: BehaviorSubject<boolean>;
  globalRouterLoaderShow_: Observable<any>;

  activeProductFired: BehaviorSubject<any>;
  activeProductFired_: Observable<any>;
  mobileNav = false;
  cartItems: any = { 'cartCount': 0, 'cartDetails': [] };
  cartCount: number = 0;
  storeChangeOnCheckout: BehaviorSubject<any>;
  storeChangeOnCheckout_: Observable<any>;
  cartDetails: any;
  isLoggedin: boolean = false;
  businessUnitId = localStorage.getItem('businessUnitId');
  customerId: number;
  visitedPage: any = {};
  homeVisited: boolean = false;
  publishCalling: number = 0;
  allStoreList: any = [];


  constructor(private metaService2: MetaService2,
    private analyticsService: GoogleAnalyticsService,
    readonly dataApiService: DataApiService,
    readonly metaService: Meta,
    readonly titleService: Title,
    private snackBar: MatSnackBar,
    readonly router: Router,
    readonly auth: AuthService,
    public dialog: MatDialog,
    private cartService: CartService,
    readonly upload: UploaderService,
    public utilityService: UtilityService,
    private location: Location,/* private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, */
    rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document
    // private hawkSearchService:HawkSearchConfigService
  ) {
    this.globalRouterLoaderShow = new BehaviorSubject(false);
    this.globalRouterLoaderShow_ = this.globalRouterLoaderShow.asObservable();
    this.activeProductFired = new BehaviorSubject(false);
    this.activeProductFired_ = this.activeProductFired.asObservable();
    this.storeChangeOnCheckout = new BehaviorSubject(false);
    this.storeChangeOnCheckout_ = this.storeChangeOnCheckout.asObservable();
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  checkLogInState() {
    this.auth.authToken.subscribe((value: any) => {
      this.isLoggedin = value.isLoggedin === 'true' ? true : false;
    });
  }

  activeMenu(payload: any) {
    this.activeProductFired.next(payload)
  }

  resize(): void {
    if (window.innerWidth < 1100) {
      this.mobileNav = true;
    } else {
      this.mobileNav = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resize();
  }


  isUserAgentForSpeedTest() {
    let userAgentString: string = navigator.userAgent;
    return (userAgentString.includes("Google Page Speed Insights")) || (userAgentString.includes("Chrome-Lighthouse")) || (userAgentString.includes("GTmetrix")) || (userAgentString.includes("gtmetrix") || (userAgentString.includes("PageSpeed Insights")));
  }

  openSnackbar(messages: any, duration: number = 4000, closeButton: string = 'Dismiss'): void {
    if (Array.isArray(messages)) {
      this.openMultipleSnackbars(messages, closeButton);
    } else {
      this.openSingleSnackbar(messages, duration, closeButton);
    }
  }

  timeoutId: any;
  private openMultipleSnackbars(messages: any[], closeButton: string): void {
    let accumulatedDuration = 0;

    messages.forEach((message, index) => {
      this.ngZone.run(() => {
        this.timeoutId = setTimeout(() => {
          this.openSnackbarMessage(message, closeButton, index, messages);
        }, accumulatedDuration);

        accumulatedDuration += message.duration;
      });
    });
  }

  private openSnackbarMessage(message: any, closeButton: string, index: number, messages: any[]): void {
    const openSnackbar = this.snackBar.open(message.text, closeButton, {
      duration: message.duration,
      verticalPosition: !this.mobileNav ? 'bottom' : 'top',
    });

    openSnackbar.afterDismissed().subscribe((event: any) => {
      if (event.dismissedByAction) {
        this.resize();
        clearTimeout(this.timeoutId);

        const nextIndex = index + 1;
        if (nextIndex < messages.length) {
          const nextMessage = messages[nextIndex];
          setTimeout(() => {
            this.openSnackbarMessage(nextMessage, closeButton, nextIndex, messages);
          }, 500);
        }
      }
    });
  }

  private openSingleSnackbar(message: any, duration: number, closeButton: string): void {
    this.resize();
    this.snackBar.open(message, closeButton, {
      duration: duration,
      verticalPosition: !this.mobileNav ? 'bottom' : 'top',
    });
  }



  setGlobalRouterLaoder(payload: any) {
    this.globalRouterLoaderShow.next(payload);
  }

  storeChangeOnCheckoutCall(payload: any) {
    this.storeChangeOnCheckout.next(payload);
  }
  /**
   * Method to get data by id.
   * @param requestData Request data for api.
   * @returns Observable of httpclient.
   */
  getDataById(requestData: any): any {
    return this.dataApiService.getDataById(requestData).pipe(
      map(data => {
        return data;
      }),
    );
  }

  /**
   * Method to get cms page based on slug.
   * @param cmsSlug Slug for cms page to be fetched.
   * @returns Observable of cms page.
   */
  getCmsPage(cmsSlug: string): any {
    return this.dataApiService.getCmsPage(cmsSlug);
  }
  getCmsPageWithOffset(cmsSlug: string, limit: number, offset: number): any {
    return this.dataApiService.getCmsPageWithOffset(cmsSlug, limit, offset);
  }
  /**
       * Method to get Category details Based on category ID.
       * @param catId Category Id;
       * @returns Observable of Category page.
       */
  getCategoryDetails(catname: string): any {
    return this.dataApiService.getCategoryDetails(catname).pipe(
      map(data => {
        return data;
      }),
    );
  }
  /**
   * Method to encode Any ID.
   * @param str received original ID.
   * @returns it generates new ID.
   */
  getEncode(str: any) {
    const specialString = 'aasfsfrsdgbnghyufufsddtrdsfgsdgsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhhrtyerteryer';
    const encription = btoa(str + specialString);
    return encription;
  }
  /**
   * Method to Decode Any ID.
   * @param str received Encoding ID.
   * @returns it generates original ID.
   */
  getDecode(str: any) {
    const specialString = 'aasfsfrsdgbnghyufufsddtrdsfgsdgsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhsdhhrtyerteryer';
    let decription: any = atob(str);
    decription = decription.split(specialString);
    return decription[0];
  }
  /**
   * @returns Observable of Navigation Item.
   */
  getNavigationItem(): any {
    return this.dataApiService.getNavigationItem();
  }

  /**
   * @returns Observable of Navigation Menu.
   */
  getTaxonomyNavigation(functionId?: any): any {
    if (functionId) {
      return this.dataApiService.getTaxonomyNavigation(functionId);
    } else {
      return this.dataApiService.getTaxonomyNavigation();
    }
  }

  getTaxonomyLevelWiseNavigation(functionId?: any, parentId?: any) {
    if (functionId) {
      if (parentId)
        return this.dataApiService.getTaxonomyLevelWiseNavigation(functionId, parentId);
      else
        return this.dataApiService.getTaxonomyLevelWiseNavigation(functionId);
    } else {
      return this.dataApiService.getTaxonomyLevelWiseNavigation();
    }
  }

  getNavFooter() {
    return this.dataApiService.getNavFooter()
  }
  getTaxonmyText(id: any) {
    return this.dataApiService.getTaxonmyText(id);
  }



  /**
   * @returns Observable of Product Listing Page.
   */
  getProductList(slug: string, filterData: any, itemId: any, params?: any, eventStoreId?: number) {
    if (filterData) {
      filterData.search = filterData.search.replace(/[`~!@$%^#&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '_');
      filterData.search = filterData.search.trim();
      return this.dataApiService.getProductList(null, filterData, null);
    }
    if (itemId && !params) {
      return this.dataApiService.getProductList(null, null, itemId);
    }
    if (itemId && params) {
      return this.dataApiService.getProductList(null, null, itemId, params, eventStoreId);
    }
    /*if (slug) {*/
    if (params) {
      return this.dataApiService.getProductList(slug, null, null, params);
    } else {
      return this.dataApiService.getProductList(slug, null, null);
    }
    //}


    // else{
    //   return this.dataApiService.getProductList(slug);
    // }

  }

  getProductBySKU(sku: any) {
    return this.dataApiService.getProductBySKU(sku).pipe(
      map(data => {
        return data;
      })
    )
  }
  getProductListInCMS(slug: string, filterData: any, itemId: any, params?: any) {
    if (filterData) {
      return this.dataApiService.getProductListforCMS(null, filterData, null);
    }
  }
  getLandingPageSlug(ids: any) {
    return this.dataApiService.getLandingPageSlug(ids);
  }
  getRelatedItems(itemStr?: any): any {
    return this.dataApiService.getRelatedItems(itemStr).pipe(
      map(data => {
        return data;
      }),
    );
  }

  // getRelatedItems() {
  //   return this.dataApiService.getRelatedItems();


  // }

  /**
 * @returns Observable of Product Listing Page.
 */
  getBrandList(itemId?: number) {
    if (itemId) {
      return this.dataApiService.getBrandList(itemId);
    } else {
      return this.dataApiService.getBrandList();
    }
  }
  getSubBrandList(itemId: number) {
    return this.dataApiService.getSubBrandList(itemId);
  }

  getAllCustomFieldList() {
    return this.dataApiService.getAllCustomFieldList();
  }

  getCollectionsData(requestData: any): any {
    return this.dataApiService.getListingData(requestData).pipe(
      map(data => {
        return data;
      }),
    );
  }

  getAllData(requestData: any): any {
    return this.dataApiService.getAllData(requestData).pipe(
      map(data => {
        return data;
      }),
    );
  }

  goBack() {
    this.location.back();
  }
  isCalledOnce: boolean = false;
  setAdMetaData(seoData: any, url: any) {
    if (this.shouldSkipMetaUpdate()) {
      return;
    }

    this.updateTitleAndDescription(seoData);

    this.updateTags([
      { property: 'og:type', content: seoData.og_type },
      { property: 'og:url', content: seoData.og_url },
      { property: 'og:title', content: seoData.og_title },
      { property: 'og:image', content: seoData.og_image },
      { property: 'og:description', content: seoData.og_description },
      { property: 'og:locale', content: seoData.og_local },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '630' },
      { property: 'og:site_name', content: "Buchheits" },
      { property: 'twitter:title', content: seoData.title },
      { name: 'title', content: seoData.title },
      { name: 'description', content: seoData.description },
      { name: 'keywords', content: seoData.keywords },
      { name: 'locale', content: seoData.meta_location },
      { name: 'robots', content: seoData.meta_robots },
      { name: 'referrer', content: seoData.meta_referral },
      { name: 'rights', content: seoData.meta_rights },
      { name: 'viewport', content: seoData.view_point },
      { name: 'twitter:description', content: seoData.og_description },
      { property: 'twitter:description', content: seoData.og_description },
      { property: 'twitter:card', content: seoData.twitter_card },
    ]);

    this.updateTwitterTags(seoData.feature_image);

    this.updateAdditionalTags();

    this.updateGeoAndAuthorTags();

    this.updateCanonicalUrl(url);

    this.updateScripts(seoData);

    if (isPlatformServer(this.platformId)) {
      this.isCalledOnce = true;
    }

    return true;
  }

  shouldSkipMetaUpdate(): boolean {
    return isPlatformServer(this.platformId) && this.isCalledOnce;
  }

  updateTitleAndDescription(seoData: any): void {
    if (seoData.title && seoData.title !== '') {
      this.titleService.setTitle(seoData.title);
      this.metaService.updateTag({ property: 'twitter:title', content: seoData.title });
      this.metaService.updateTag({ name: 'title', content: seoData.title });
    }

    if (seoData.description && seoData.description !== '') {
      this.metaService.updateTag({ name: 'description', content: seoData.description });
    }
  }

  updateTags(tags: { property?: string; name?: string; content: string }[]): void {
    tags.forEach(tag => {
      if (tag.content && tag.content !== '') {
        this.metaService.updateTag(tag);
      }
    });
  }


  updateTwitterTags(featureImage: string): void {
    if (featureImage && featureImage !== '') {
      this.metaService.updateTag({ property: 'twitter:image', content: featureImage });
      this.metaService.updateTag({ name: 'twitter:image', content: featureImage });
      this.metaService.updateTag({ name: 'twitter:site', content: '@Buchheit' });
    }
  }

  updateAdditionalTags(): void {
    this.metaService.updateTag({ name: 'geo.country', content: 'US' });
    this.metaService.updateTag({ name: 'author', content: 'Buchheits' });
  }

  updateGeoAndAuthorTags(): void {
    this.metaService.updateTag({ property: 'og:image:width', content: '1200' });
    this.metaService.updateTag({ property: 'og:image:height', content: '630' });
    this.metaService.updateTag({ property: 'og:site_name', content: 'Buchheits' });
  }

  urls: any[] = [
    'https://www.buchheits.com/cart',
    'https://www.buchheits.com/shop',
    'https://www.buchheits.com/checkout/order-review',
    'https://www.buchheits.com/checkout/checkout-billing',
    'https://www.buchheits.com/checkout/order-complete',
    'https://www.buchheits.com/user/yourprofile',
    'https://www.buchheits.com/user/changepassword',
    'https://www.buchheits.com/user/yourorder',
    'https://www.buchheits.com/user/orderhistory',
    'https://www.buchheits.com/user/yourwishlist',
    'https://www.buchheits.com/user/youraddress',
    'https://www.buchheits.com/user/yourrebates',
    'https://www.buchheits.com/user/yourgiftcards'
  ];

  doesAnyUrlMatch(urls: string[], searchString: string): boolean {
    return urls.some(url => url === searchString);
  }

  updateCanonicalUrl(url: string): void {
    if (!environment.isDevServer) {
      const canonicalUrl = 'https://www.buchheits.com' + url;
      const callCanonical = canonicalUrl.includes('.js') || canonicalUrl.includes('.png') || canonicalUrl.includes('.ico') || canonicalUrl.includes('.css') || this.doesAnyUrlMatch(this.urls, canonicalUrl);

      if (!callCanonical && canonicalUrl !== 'https://www.buchheits.com/') {
        this.metaService2.removeTags(); // Moved inside the condition
        this.metaService2.addTag({ rel: 'canonical', href: canonicalUrl });
      }
    }
  }


  updateScripts(seoData: any): void {
    if (seoData.cms_script && seoData.cms_script !== '') {
      this.appendGlobalScript(seoData.cms_script);
    }
    if (seoData.cms_noscript && seoData.cms_noscript !== '') {
      this.appendGlobalNoScript(seoData.cms_noscript);
    }
    if (seoData.cms_function_script && seoData.cms_function_script !== '') {
      this.appendGlobalFunctionScript(seoData.cms_function_script);
    }
  }

  appendGlobalScript(script: any) {
    let scriprtArr = script.split("</script>");
    scriprtArr.forEach((data: any) => {
      if (data && data !== '') {
        const hasSrc = /<script\s+[^>]*src="([^"]+)"[^>]*>/i;
        const scriptHasSrc = hasSrc.test(data);
        if (!scriptHasSrc) {
          this.appendCmsFunctionScript(data);
          return;
        }
        var regex = /<script.*?src="(.*?)"/gmi;
        var regexOne = /<script.*?id="(.*?)"/gmi;
        var url = regex.exec(data);
        var id = regexOne.exec(data);
        const chatScript = this.renderer.createElement('script');
        chatScript.type = "text/javascript";
        chatScript.async = true;
        if (url) {
          chatScript.src = url[1];
        }

        if (id) {
          chatScript.id = id[1];
        } else {
          chatScript.id = 'cms-page-script';
        }
        this.renderer.appendChild(this.document.head, chatScript);
      }

    });
  }
  appendCmsFunctionScript(script: any) {
    if (script !== '') {
      const newScript = script.replace('<script>', '');
      const functionScript = this.renderer.createElement('script');
      functionScript.text = `${newScript}`;
      functionScript.id = `cms-function-script`;
      this.renderer.appendChild(this.document.head, functionScript);
    }
  }

  appendGlobalNoScript(noscript: any) {
    let noscriprtArr = noscript.split("</noscript>");

    noscriprtArr.forEach((data: any) => {
      if (data !== '') {
        const newScript = data.replace('<noscript>', '');

        var regex = /<iframe.*?src="(.*?)"/gmi;
        var regexHeight = /(height)=["']([^"']*)["']/gi;
        var regexwidth = /(width)=["']([^"']*)["']/gi;
        var regexsrc = /(src)=["']([^"']*)["']/gi;
        var regexstyle = /(style)=["']([^"']*)["']/gi;


        var src = regexsrc.exec(newScript);
        var height = regexHeight.exec(newScript);
        var width = regexwidth.exec(newScript);


        const noScript = this.renderer.createElement('noscript') as HTMLScriptElement;
        const iframe = this.renderer.createElement('iframe') as HTMLIFrameElement;
        iframe.src = src[2];

        iframe.width = width[2];
        iframe.height = height[2];
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';
        this.renderer.appendChild(noScript, iframe);
        document.body.appendChild(noScript);

      }
    });
  }
  appendGlobalFunctionScript(script: any) {
    let scriprtArr = script.split("</script>");
    scriprtArr.forEach((a: any) => {
      if (a !== '') {
        const newScript = a.replace('<script>', '');
        const script2 = this.renderer.createElement('script') as HTMLScriptElement;
        const scriptBody = this.renderer.createText(`${newScript}`);
        this.renderer.appendChild(script2, scriptBody);
        document.head.appendChild(script2);
      }
    })

  }

  addScript(url: string, type?: string, id?: string) {
    let chatScript = document.createElement("script");
    chatScript.type = type || "text/javascript";
    chatScript.async = true;
    chatScript.src = url;

    if (id) {
      chatScript.id = id;
    }

    document.body.appendChild(chatScript);
  }

  getBaseUrl() {
    const baseUrl = window.location.origin;
    return baseUrl;
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          resolve({ lng: '', lat: '' });
        });
    });

  }

  /**
   * Method to post recent product view parameter.
   * @param bodyobject object of key value;
   * @returns Observable of recent view product.
   */
  postRecentProductView(bodyobject: any): any {
    if (bodyobject.customer !== '') {
      return this.dataApiService.postRecentProductView(bodyobject).pipe(
        map((data) => {
          return data;
        })
      );
    }
  }

  storeRecentProductViewInLocalSorage(bodyobject: any): any {
    let previousRecentViewedItemsArray: any = [];
    const previousRecentViewedItems = localStorage.getItem('recentViewedItems');
    if (previousRecentViewedItems) {
      previousRecentViewedItemsArray = previousRecentViewedItems.split(",");
    }
    previousRecentViewedItemsArray = previousRecentViewedItemsArray.filter((item: any, pos: any) => previousRecentViewedItemsArray.indexOf(item) === pos)
    let recentViewedItems: any = [];
    let itemIndex = previousRecentViewedItemsArray.indexOf(String(bodyobject.item));
    if (itemIndex > -1) {
      previousRecentViewedItemsArray.splice(itemIndex, 1);
    }
    previousRecentViewedItemsArray.unshift(bodyobject.item);
    if (previousRecentViewedItemsArray.length > 20) {
      previousRecentViewedItemsArray.splice(20);
    }
    recentViewedItems = previousRecentViewedItemsArray.join(",");
    if (recentViewedItems) {
      localStorage.setItem('recentViewedItems', recentViewedItems);
    }
  }

  /**
   * Method to post review rating parameter.
   * @param bodyobject object of key value;
   * @returns Observable of review rating.
   */
  postProductReviewsRatings(bodyobject: any): any {
    return this.dataApiService.postProductReviewsRatings(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Method to put review rating parameter.
   * @param bodyobject object of key value;
   * @returns Observable of review rating.
   */
  productReviewsRatingsUpdate(review_id: any, bodyobject: any): any {
    return this.dataApiService.productReviewsRatingsUpdate(review_id, bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * Method to post question parameter.
   * @param bodyobject object of key value;
   * @returns Observable of question.
   */
  postQuestion(bodyobject: any): any {
    return this.dataApiService.postQuestion(bodyobject).pipe(
      map((data) => {
        return data;
      })
    );
  }
  getCommonDropDowndata(objectIds: any): any {
    return this.dataApiService.getCommonDropDowndata(objectIds).pipe(
      map(data => {
        return data;
      }),
    );
  }
  getStateByCountry(countryId: number): any {
    return this.dataApiService.getStateByCountry(countryId).pipe(
      map(data => {
        return data;
      })
    )
  }

  wishListData(wishItemId?: any, active?: boolean): any {
    return this.dataApiService.wishListData(wishItemId, active).pipe(
      map(data => {
        return data;
      })
    )
  }

  getCartDetails(customerOrderId?: any): any {

    return this.dataApiService.getCartDetails(customerOrderId).pipe(
      map(data => {
        return data;
      })
    )
  }

  openCmsUrl(url: string, openInSameTab: boolean) {
    if (openInSameTab) {
      if (url.startsWith(window.location.origin)) {
        this.router.navigateByUrl(`${url.slice(window.location.origin.length)}`)
      } else {
        window.location.href = url;
      }
    } else {
      window.open(`${url}`, '_blank');
    }
  }

  setCartItems(payload: any) {
    payload?.cartDetails.map((x: any) => x.store = payload?.store);
    this.cartItems = { ...payload };
    this.cartCount = payload.cartCount;
    this.cartDetails = [...payload.cartDetails];
    this.cartService.setCount(this.cartCount);
    this.cartService.setCartItemArray([...payload.cartDetails]);
    this.cartService.setTotalOfferAmount(payload.totalOfferAmount);
    this.cartService.setTotalDiscount(payload.totalDiscount);
    this.cartService.setTotalAmount(payload.totalAmount);
    this.cartService.setTotalTaxAmount(payload.total_tax);
    this.cartService.setBookingData = (payload.booking_flag ? payload.booking_data : {});
    this.cartService.setCartDetails(payload.cartDetails);
    this.cartService.setIsCartSet(payload.cartDetails);
  }

  getCount() {
    return this.cartCount;
  }

  clearBookingData() {
    localStorage.removeItem('booking_flag');
    localStorage.removeItem('booking_store');
    localStorage.removeItem('pickup_window_id');
  }

  getCartItem(calledFrom?: string): any {
    this.checkLogInState();

    if (!this.isLoggedin) {
      this.getlocalStoreCartDeatils();
      return;
    }

    this.getCartDetails(null).subscribe(
      (existCartData: any) => this.handleCartData(existCartData),
      (error) => this.handleCartError(error)
    );
  }

  private handleCartData(existCartData: any): void {
    if (existCartData.count > 0) {
      this.handleExistingCart(existCartData);
    } else {
      this.handleEmptyCart(existCartData);
    }
  }

  private handleCartError(error: any): void {
    if (!error.error.booking_flag) {
      this.router.navigate(['/']);
      return;
    }

    if (!this.getPoupCalled()) {
      if (error.error.count > 0) {
        this.handleExistingCart(error.error);
      } else {
        this.handleEmptyCart(error.error);
      }

      this.setPoupCalled(true);


      setTimeout(() => {
        this.handleDialogAndNavigation(error.error)
      }, 1000);
    }
  }
  private handleDialogAndNavigation(error: any): void {
    const dialogRef = this.openBookingvalidationErrorDialog(error?.message);

    dialogRef.afterClosed().subscribe((result: any) => {
      this.setPoupCalled(false);
      this.handleBookingFlagError(error);
    });
  }

  // Getter method to access the flag value
  getPoupCalled(): boolean {
    return this.cartService.poupCalled;
  }

  // Setter method to update the flag value
  setPoupCalled(value: boolean): void {
    this.cartService.poupCalled = value;
  }

  private openBookingvalidationErrorDialog(message: string) {
    return this.dialog.open(AlertModalComponent, {
      panelClass: ['pop-up', 'sm-pop'],
      data: {
        displayMsg: `${message}`,
        confirmButtonText: 'Okay',
        cancelBtn: false,
        closeBtn: true,
        from: 'bookingError'
      }
    });
  }
  handleBookingFlagError(error: any): void {
    if (error?.booking_flag) {
      const deletePromiseArray = error?.data.map((item) => this.deleteCartNew(item.customerorderproductlineitem_id, null));
      Promise.all(deletePromiseArray)
        .then(() => {
          setTimeout(() => {
            if (error?.booking_data?.booking_id && (this.router.url.includes('checkout') || this.router.url.includes('cart'))) {
              this.router.navigate([`booking/${error.booking_data.booking_id}`]);
              this.getCartItem();
              return;
            } else {
              this.getCartItem();
              return;
            }
          }, 1000)
        })
        .catch((error) => {
          console.error('Error resetting cart:', error);
        });
    }
  }

  // Helper methods for better readability
  handleExistingCart(existCartData: any): void {
    this.cartService.setCartDuringLogin(existCartData);
    this.callAfterFetchWithLogin(existCartData);

    const { booking_flag, store } = existCartData;

    if (booking_flag) {
      localStorage.setItem('booking_flag', booking_flag);
      localStorage.setItem('booking_store', store);

    } else {
      // This is for the local storage works if the cart data is not from booking 
      localStorage.setItem('booking_flag', 'false');
      localStorage.removeItem('booking_store');
    }
  }


  handleEmptyCart(existCartData: any): void {
    this.setCartItems({
      'cartCount': existCartData.count,
      'cartDetails': existCartData.data,
      'totalDiscount': existCartData.total_discount,
      'totalOfferAmount': existCartData.total_offer_amount,
      'totalAmount': existCartData.totalamount,
      'total_tax': existCartData.total_tax
    });
    localStorage.setItem('booking_flag', 'false');
    localStorage.removeItem('booking_store');
    localStorage.removeItem('pickup_window_id');
    let element = document.getElementById("Cart-Box");
    element?.classList.remove("active");
  }
  // getCartItem(): any {
  //   this.checkLogInState();
  //   if (!this.isLoggedin) {
  //     this.getlocalStoreCartDeatils();
  //   } else {
  //     this.getCartDetails(null).subscribe((existCartData: any) => {
  //       this.setCartItems({ 'cartCount': existCartData.count, 'cartDetails': existCartData.data, 'totalDiscount': existCartData.total_discount, 'totalOfferAmount': existCartData.total_offer_amount, 'totalAmount': existCartData.totalamount, 'total_tax': existCartData.total_tax });
  //     })
  //   }
  // }


  // Stocks Finder after conversion starts here for user with login starts here.
  /**
 * callAfterFetchWithLogin() this is used for getting the stocks as per the qualifier.
 * This is a async function & will wait for  getInventoryStockWithLogin() method.
 * @param data
 */
  async callAfterFetchWithLogin(data: any) {
    try {
      const inventoryStock: InventoryStock = await this.getInventoryStockWithLogin(data);

      if (Object.keys(inventoryStock).length > 0) {
        const cartItems = {
          'cartCount': inventoryStock.count,
          'cartDetails': inventoryStock.data,
          'totalDiscount': inventoryStock.total_discount,
          'totalOfferAmount': inventoryStock.total_offer_amount,
          'totalAmount': inventoryStock.totalamount,
          'total_tax': inventoryStock.total_tax,
          'booking_flag': data?.booking_flag,
          'booking_data': data?.booking_data,
          'store': inventoryStock.store
        };

        this.setCartItems(cartItems);
      }
    } catch (error) {
      console.error('Error fetching inventory stock:', error);
      // Handle the error if needed
    }
  }

  getInventoryStockWithLogin(data: any): Promise<InventoryStock> {
    return new Promise((resolve: any, reject: any) => {
      let newArr: any = '';
      data['data'].forEach((e: any, i: number) => {
        if (i == 0) {
          newArr += 'items=' + e.item_id + ',' + e.positemqualifier;
        } else {
          newArr += '&items=' + e.item_id + ',' + e.positemqualifier;
        }
      })
      if (newArr !== '') {
        this.checkItemsInStock(newArr).subscribe((value: any) => {
          if (value && value.stock && value.stock.length > 0) {
            value.stock.forEach((e: any, index: number) => {
              data['data'].forEach((f: any, i: number) => {
                if (e.item_id == f.item_id) {
                  f.rest_stock = e.stock;
                  f.ignore_stock = e.ignore_stock;
                }
              })
            })
            resolve(data);
          }
        })
      }
    })
  }
  // Stocks Finder after conversion starts here for user with login end here.



  // Stocks Finder after conversion starts here for user without login starts here.
  /**
   * callAfterFetchWithoutLogin() this is used for getting the stocks as per the qualifier.
   * This is a async function & will wait for  getInventoryStockWithoutLogin() method.
   * @param data
   */
  async callAfterFetchWithoutLogin(data: any) {
    try {
      const response: InventoryStock = await this.getInventoryStockWithoutLogin(data);

      if (response) {
        const { data: cartLocalDataInfo, total_tax, total_discount, total_offer_amount, totalamount, booking_id, event_id, ordertype, storeid } = response[0];

        this.setCartItems({
          'cartCount': cartLocalDataInfo.length,
          'cartDetails': cartLocalDataInfo,
          'totalDiscount': total_discount,
          'totalOfferAmount': total_offer_amount,
          'totalAmount': totalamount,
          'total_tax': total_tax || 0,
          'booking_flag': ordertype ? true : false,
          'booking_data': { booking_id, event_id, ordertype, storeid }
        });
      }
    } catch (error) {
      console.error('Error fetching inventory stock without login:', error);
      // Handle the error if needed
    }
  }
  getInventoryStockWithoutLogin(data: any): Promise<InventoryStock> {
    return new Promise((resolve: any, reject: any) => {
      let newArr: any = '';
      data[0].data.forEach((e: any, i: number) => {
        if (i == 0) {
          newArr += 'items=' + e.itemdata.Item.id + ',' + e.positemqualifier;
        } else {
          newArr += '&items=' + e.itemdata.Item.id + ',' + e.positemqualifier;
        }
      })
      if (newArr !== '') {
        this.checkItemsInStock(newArr).subscribe((value: any) => {
          if (value && value.stock && value.stock.length > 0) {
            value.stock.forEach((e: any, index: number) => {
              data[0].data.forEach((f: any, i: number) => {
                if (e.item_id == f.itemdata.Item.id) {
                  f.rest_stock = e.stock;
                  f.ignore_stock = e.ignore_stock;
                }
              })
            })
            resolve(data);
          }
        })
      }
    })
  }
  // Stocks Finder after conversion starts here for user without login end here.


  addCart(item: any, type?: string, moveFromWishlist?: boolean, bookingData?: any) {
    if (type === 'add') {
      this.analyticsService.addToCart(item);
    }
    if (type === 'remove' && item.itemCount !== 0) {
      this.analyticsService.removeFromCart(item);
    }

    this.checkLogInState();

    if (!item.Item) {
      item.Item = item.itemdata.Item;
    }

    if (!this.isLoggedin) {
      this.handleCartForGuest(item, type, moveFromWishlist, bookingData);
    } else {
      this.handleCartForLoggedInUser(item, type, moveFromWishlist, bookingData);
    }
  }

  private handleAddToCartResponse(value: any, type: string, moveFromWishlist?: boolean, obj2?: any) {
    // Handle the response as needed
    if (value?.booking_flag) {
      /**
     * Handles the case where a booking product with the same event ID is already present in the cart,
     * and a new product with the same event ID but a different store ID is being added.
     */
      if (value?.data?.itemadded == false) {
        this.openSnackbar(value.data.message);
        return;
      }
      this.handleBookingFlag(value?.booking_flag, obj2.storeid);
    }

    if (value?.is_processed) {
      this.handleProcessedResponse(value);
      return;
    }

    if (value.hasOwnProperty('data') && value.data.itemadded == false) {
      this.handleItemNotAddedResponse(value);
    } else {
      this.handleSuccessfulAddition(type, moveFromWishlist);
    }
  }

  private handleBookingFlag(value: any, storeid: any) {
    localStorage.setItem('booking_flag', value);
    localStorage.setItem('booking_store', storeid);
  }

  private handleProcessedResponse(value: any) {
    this.openSnackbar(value.message);
    this.getCartItem();
  }

  private handleItemNotAddedResponse(value: any) {
    this.getCartDetails(null).subscribe((data: any) => {
      this.cartService.setcartDataNew(data);
    });

    const currentStore = localStorage.getItem('storeName');
    const array = this.cartService.getAllsoreData();
    const previous_store = value.data?.hasOwnProperty('previous_store') ? value.data.previous_store : null;
    const previousStoreArray = array.length > 0 ? array.filter((a: any) => +a.id == +previous_store) : [];

    const dialogRef = this.openChangeStoreDialog(currentStore, array, previousStoreArray);
    dialogRef.afterClosed().subscribe((result: any) => {
      this.handleStoreDialogResult(result, array, previousStoreArray);
    });
  }

  private openChangeStoreDialog(currentStore: string | null, array: any[], previousStoreArray: any[]) {
    return this.dialog.open(AlertModalComponent, {
      panelClass: ['pop-up', 'sm-pop'],
      data: {
        displayTitle: 'Shopping Location Changed!',
        displayMsg: `You already have items in your cart for ${previousStoreArray[0].storename} store. Do you want to abandon your cart and shop at ${currentStore}? Note: Your cart will be reset.`,
        confirmButtonText: 'Reset Cart'
      }
    });
  }

  private handleStoreDialogResult(result: any, array: any[], previousStoreArray: any[]) {
    this.cartService.setclosingStoreBox(true);
    if (result === "success") {
      const currentStoreId = localStorage.getItem('storeId');
      const savedStoreArray = array.length > 0 ? array.filter((a: any) => +a.id == +currentStoreId) : [];
      if (savedStoreArray.length) this.changeStore(savedStoreArray[0]);
    } else {
      this.handleStoreDialogFailure(previousStoreArray);
    }
  }

  private handleStoreDialogFailure(previousStoreArray: any[]) {
    if (previousStoreArray.length) {
      localStorage.setItem('storeId', previousStoreArray[0].id);
      localStorage.setItem('storenumber', previousStoreArray[0].storenumber);
      localStorage.setItem('businessUnitId', previousStoreArray[0].businessunit);
      localStorage.setItem('storeName', previousStoreArray[0].storename);
      this.openSnackbar(`Your Location is set to ${previousStoreArray[0].storename} store.`);
    }

    if (this.router.url.includes('/checkout') || this.router.url.includes('/cart')) {
      this.router.navigate(['/cart']);
    } else {
      this.router.navigate(['']);
    }
  }

  private handleSuccessfulAddition(type: string, moveFromWishlist?: boolean) {
    this.getCartItem();
    if (type == 'add') {
      if (moveFromWishlist) {
        this.openSnackbar("Product has been moved from wishlist to cart");
      } else {
        this.ngZone.run(() => {
          this.openSnackbar("Added to Cart!");
        });
      }
    }
    this.auth.getCouponVerify('remove');
  }


  private handleAddToCartError(err: any, item: any, booking_data: any, msg?: any) {
    if (err?.error.clear_cart) {
      this.getCartItem();
      this.openSnackbar(err?.error.message);
      this.router.navigate(['/booking']);
      return;
    }
    // This below change is for ticket PIM-2751
    if (err?.error?.is_removed) {
      const dialogRef = this.openBookingvalidationErrorDialog(err?.error?.message);

      dialogRef.afterClosed().subscribe((result: any) => {
        this.dataApiService.reloadCurrentRoute();
      });
      return;
    }
    if (err?.error.message || msg) {
      // Handle error message
      const dialogRef = this.dialog.open(AlertModalComponent, {
        panelClass: ['pop-up', 'sm-pop'],
        data: {
          displayTitle: 'Reset cart!', displayMsg: err?.error.message || msg, closeBtn: true, confirmButtonText: 'Complete Purchase', cancelButtonText: 'Reset cart'
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(async (result: any) => {
        this.cartService.setclosingStoreBox(true);
        if (result === "success") {
          // Complete purchase functionality
          this.router.navigate(['/cart']);
        } else if (result == 'Reset cart') {
          // Reset cart functionality'
          this.handleResetCart(item, booking_data);
        }
      });
    }
  }

  private handleResetCart(item: any, booking_data: any) {
    let cartDataInfo: any = [];
    cartDataInfo = this.cartService.getCartItemArray();
    if (cartDataInfo.length === 0) {
      this.getCartItem();
    }
    cartDataInfo = this.cartService.getCartItemArray();
    const deletePromises = cartDataInfo.map(item => this.deleteCartNew(item.customerorderproductlineitem_id, null));

    Promise.all(deletePromises)
      .then((values) => {
        this.getCartItem();
        this.addCart(item, 'add', false, booking_data);
      })
      .catch((error) => {
        console.error('Error resetting cart:', error);
      });
  }
  private handleCartForGuest(item: any, type: string, moveFromWishlist?: boolean, bookingData?: any) {
    if (item.quantity > 0) {
      this.updateCartDetailsStorage(item, bookingData);
      this.postlocalStoreCartDeatils();
    } else if (item.quantity === 0 && type === 'remove') {
      this.ngZone.run(()=> this.openAlertModal(item));
      let element = this.cartItems;
      let cartDataInfo = element.cartDetails;
      cartDataInfo.filter((x: any) => +x.item_id !== +item.Item.id);
    }
  }

  private updateCartDetailsStorage(item: any, bookingData?: any) {
    const cartDetailsStorageArr = JSON.parse(localStorage.getItem('cartDetailsStorage')) || [];

    // Early exit if items from different events/stores
    if (cartDetailsStorageArr.some(
      (existingItem) =>
        existingItem.booking_item !== item.booking || existingItem.event_id !== bookingData?.event_id
    )) {
      this.handleAddToCartErrorLoggedOutUser(
        null,
        item,
        bookingData,
        'Please complete your current purchase or clear your cart to proceed, as it contains items from different events/store'
      );
      return;
    }

    const existingItem = cartDetailsStorageArr.find((x) => x.item_id === item.Item.id);

    if (existingItem) {
      existingItem.quantity = item.quantity || item.itemCount;
    } else {
      const booking_item = this.router.url.includes('/b/') || this.router.url.includes('/booking/');
      const obj = {
        'item_id': +item.Item.id,
        'quantity': item.quantity || item.itemCount,
        'positemqualifier': item.positemqualifier,
        'enddate': item?.enddate,
        'special_instructions': item?.special_instructions,
        'description': item?.Item?.name || '',
        booking_item,
      };
      if (bookingData) {
        Object.assign(obj, bookingData);
      }
      cartDetailsStorageArr.push(obj);
    }

    if (bookingData?.ordertype === "booking") {
      this.handleBookingFlag(true, bookingData?.storeid);
    }

    localStorage.setItem('cartDetailsStorage', JSON.stringify(cartDetailsStorageArr));
    this.cartCount = cartDetailsStorageArr.length;
  }


  private handleAddToCartErrorLoggedOutUser(err: any, item: any, booking_data: any, msg?: any) {
    if (err?.error.message || msg) {
      // Handle error message
      const dialogRef = this.dialog.open(AlertModalComponent, {
        panelClass: ['pop-up', 'sm-pop'],
        data: {
          displayTitle: 'Reset cart!', displayMsg: err?.error.message || msg, closeBtn: true, confirmButtonText: 'Complete Purchase', cancelButtonText: 'Reset cart'
        },
        disableClose: true
      });

      dialogRef.afterClosed().subscribe(async (result: any) => {
        this.cartService.setclosingStoreBox(true);
        if (result === "success") {
          // Complete purchase functionality
          this.router.navigate(['/cart']);
        } else if (result == 'Reset cart') {
          // Reset cart functionality'
          this.handleResetCartLoggedOutUser(item, booking_data);
        }
      });
    }
  }
  private handleResetCartLoggedOutUser(item: any, bookingData: any) {
    let cartDetailsStorageArr = [];
    const booking_item = this.router.url.includes('/b/') || this.router.url.includes('/booking/') === true;
    let obj = {
      'item_id': +item.Item.id,
      'quantity': item.quantity || item.itemCount,
      'positemqualifier': item.positemqualifier,
      'enddate': item.enddate,
      'special_instructions': item.special_instructions,
      'description': item?.Item?.name || '',
      'booking_item': booking_item || false
    };

    if (bookingData && Object.keys(bookingData).length) {
      obj = { ...obj, ...bookingData };
    }

    cartDetailsStorageArr.push(obj);
    booking_item && this.handleBookingFlag(true, bookingData?.storeid);
    localStorage.setItem('booking_flag', booking_item ? 'true' : 'false');
    if (!booking_item) {
      localStorage.removeItem('booking_store');
      localStorage.removeItem('pickup_window_id');
    }
    localStorage.setItem('cartDetailsStorage', JSON.stringify(cartDetailsStorageArr));
    this.cartCount = cartDetailsStorageArr.length;
    this.getCartItem();

  }

  private handleCartForLoggedInUser(item: any, type: string, moveFromWishlist?: boolean, bookingData?: any) {
    if (item.quantity === undefined) {
      item.quantity = item.itemCount || 0;
    }

    if (item.quantity > 0) {
      const cartDetailsArr = this.prepareCartDetailsArr(item);

      const obj2 = this.prepareObj2(cartDetailsArr, bookingData);

      this.addToCart(obj2, null).subscribe(
        (value: any) => this.handleAddToCartResponse(value, type, moveFromWishlist, obj2),
        (err: any) => this.handleAddToCartError(err, item, bookingData)
      );
    } else if (item.quantity === 0 && type === 'remove') {
      this.ngZone.run(()=> this.openAlertModal(item));
      this.cartItems.cartDetails = this.cartItems.cartDetails.filter((x: any) => +x.item_id !== +item.Item.id);
    }
  }

  private prepareCartDetailsArr(item: any): any[] {
    const cartDetailsArr: any[] = [];

    if (item.hasOwnProperty('qualifier_data') && item.qualifier_data !== null) {
      item.positemqualifier = item.qualifier_data.positemqulifier;
    } else if (item.positemqualifier_id) {
      item.positemqualifier = item.positemqualifier_id;
    }

    const obj: any = {
      item_id: +item.Item.id,
      quantity: item.quantity,
      price: item.regularPrice,
      description: item.Item.name,
      positemqualifier: item.positemqualifier,
    };

    if (item?.itemimages?.length > 0) {
      obj.item_image = this.utilityService.getLazyImage(
        this.getProductDefaultImage(item, true, item.positemqualifier).image,
        'items',
        true,
        `50x50`
      );
    }

    cartDetailsArr.push(obj);

    return cartDetailsArr;
  }

  private prepareObj2(cartDetailsArr: any[], bookingData?: any): any {
    const obj2: any = {
      businessunitid: this.businessUnitId,
      storeid: localStorage.getItem('storeId'),
      customerid: this.auth.customer_id,
      status: 'Active',
      lineitems: cartDetailsArr,
    };
    if (bookingData?.booking_id) {
      return { ...obj2, ...bookingData };
    }

    return obj2;
  }

  deleteCartNew(customerOrderProductLineItemId, otherParameters) {
    return new Promise((resolve, reject) => {
      this.deleteCart(customerOrderProductLineItemId, null).subscribe((value: any) => {
        resolve(value)
      }, error => {
        // reject(error)
      })
    });
  }
  cartDataArray: any;

  // ++ Before Login Cart section implementation

  getlocalStoreCartDeatils() {
    let cartDetailsStorage = JSON.parse(localStorage.getItem('cartDetailsStorage'));
    let itemStr = '';
    if (cartDetailsStorage) {
      let itemIdArr: any = [];
      cartDetailsStorage.forEach((e: any, i: number) => {
        if (itemIdArr.indexOf(e.item_id) == -1) {
          itemIdArr.push(e.item_id);
          itemStr += e.item_id.toString() + ',';
        }
      });
      itemStr = itemStr.slice(0, -1);

      if (itemStr !== '') {
        this.getLocalCartItems(cartDetailsStorage, +this.businessUnitId).subscribe((value: any) => {
          if (value && value[0].data && value[0].data.length > 0) {
            this.callAfterFetchWithoutLogin(value);
          }
        });
      }
      else {
        this.setCartItems({ 'cartCount': 0, 'cartDetails': [], 'totalAmount': 0 });
        this.clearBookingData();
        let element = document.getElementById("Cart-Box");
        element?.classList.remove("active");
      }
    }
  }


  // ++ After Login Cart section implementation
  postlocalStoreCartDeatils() {
    this.getCartItem();
  }


  // ++ Remove Item Cart
  openAlertModal(item?: any): void {
    let cartDataInfo: any = [];
    cartDataInfo = this.cartService.getCartItemArray();
    if (cartDataInfo.length == 0) {
      this.getCartItem();
    }
    cartDataInfo = this.cartService.getCartItemArray();

    let itemId = +(item?.Item?.id || item?.item_id);
    let posItemQualifier = +item.positemqualifier;

    let cartDataDetails = cartDataInfo.filter((x: any) => 
      +x.item_id === itemId && +x.positemqualifier === posItemQualifier
    );

    if (cartDataDetails.length > 0) {
      item.customerorderproductlineitem_id = cartDataDetails[0]?.customerorderproductlineitem_id;
    }

    this.checkLogInState();
    const dialogRef = this.dialog.open(AlertModalComponent, {
      panelClass: ['pop-up', 'sm-pop'],
      data: {
        //productlineitemid: +item.customerorderproductlineitem_id
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === "success") {
        item.cartBtn = false;
        let cartDataInfo: any = [];
        let element = this.cartItems;
        cartDataInfo = element.cartDetails;

        if (!this.isLoggedin) {
          this.handleGuestUserCartUpdate(item);
        } else {
          this.deleteProductFormCart(item, cartDataInfo);
          this.analyticsService.removeFromCart(item);
        }
      }
    });

  }
  private handleGuestUserCartUpdate(item: any) {
    this.openSnackbar("A product has been removed from the cart.");
    let cartDetailsStorage = JSON.parse(localStorage.getItem('cartDetailsStorage'));
    
    if (cartDetailsStorage) {
      this.removeFromLocalStorageCart(cartDetailsStorage, item);
      this.updateCartBoxVisibility(cartDetailsStorage);
      localStorage.setItem('cartDetailsStorage', JSON.stringify(cartDetailsStorage));
    }

    this.getCartItem();
    this.updateSearchResults(item);
  }
  private removeFromLocalStorageCart(cartDetailsStorage: any[], item: any) {
    const index = cartDetailsStorage.findIndex((x: any) =>
      +x.item_id === (+item?.Item?.id || +item?.item_id) &&
      +x.positemqualifier === +item.positemqualifier
    );

    if (index !== -1) {
      cartDetailsStorage.splice(index, 1);
    }
  }

  private updateCartBoxVisibility(cartDetailsStorage: any[]) {
    if (!cartDetailsStorage.length) {
      let element = document.getElementById("Cart-Box");
      element?.classList.remove("active");
    }
  }

  private updateSearchResults(item: any, cartData: any = []) {
    const searchResultsList: any = document.querySelector('hawksearch-search-results')
      ?.shadowRoot.querySelector('hawksearch-search-results-list')
      ?.shadowRoot.querySelectorAll('hawksearch-search-results-item');
  
    if (!searchResultsList?.length) return;
  
    const itemId = +(item.item_id || item.Item.id);
    const posItemQualifier = +(item.positemqualifier || item.Item.positemqualifier);
  
    // If cartData has no length and item count is 0, update matched search results
    if ((!cartData?.length && item?.itemCount == 0) || 
        (cartData?.length && cartData.findIndex((x: any) =>
          +x.item_id === itemId && +x.positemqualifier === posItemQualifier) == -1 && item?.itemCount == 0)) {
      this.updateMatchedSearchResults(searchResultsList, item);
    }
  }
  


  private updateMatchedSearchResults(nodeList: any, deletedItem: any) {
    nodeList?.forEach((nodeItem: any) => {
      if (+(nodeItem.data.id) === +deletedItem.Item.id) {
        const addBtn = nodeItem.shadowRoot.querySelector('.addBtn');
        addBtn.innerHTML = this.getAddToCartButtonHtml(deletedItem);
      }
    });
  }

  private getAddToCartButtonHtml(item: any) {
    return `
      <a class="product-button" id="add-to-cart-btn" onclick="addToCart('${item?.item_id}','${item?.totalofferunitsprice}')">Add To Cart</a>
    `;
  }

  private updateSearchResultsForNoStock(item: any) {

    const searchResultsItems: any = this.getSearchResultsItems();
    if (!searchResultsItems?.length) return;

    const cartData = this.cartService.getCartItemArray();

    if (cartData.length) {
      const lessStockItem = this.findLessStockItem(cartData, item);
      const maxCount = item?.maximumsaleunitcount > 0
        ? Math.min(item?.rest_stock, item?.maximumsaleunitcount)
        : item?.rest_stock;

      if (
        lessStockItem && item.quantity > maxCount
      ) {
        this.updateMatchedSearchResultsForNoStock(searchResultsItems, lessStockItem);
      } else {
        this.updateSearchResultsWithCartData(searchResultsItems, item);
      }
    } else {
      this.updateMatchedSearchResults(searchResultsItems, item);
    }
  }

  private getSearchResultsItems(): NodeListOf<Element> | null {
    return document.querySelector('hawksearch-search-results')
      ?.shadowRoot.querySelector('hawksearch-search-results-list')
      ?.shadowRoot.querySelectorAll('hawksearch-search-results-item') ?? null;
  }

  private findLessStockItem(cartData: any[], item: any): any {
    return cartData.find((x: any) => +x.item_id === item.item_id) || null;
  }

  private updateSearchResultsWithCartData(searchResultsItems: any[], item: any): void {
    searchResultsItems.forEach((nodeItem: any) => {
      if (+nodeItem.data.id === +item.item_id) {
        this.updateNodeItemButtons(nodeItem, item);
        this.updateNodeItemInventory(nodeItem, item);
      }
    });
  }

  private updateNodeItemButtons(nodeItem: any, item: any): void {
    const addBtn = nodeItem.shadowRoot.querySelector('.addBtn');
    const decreaseBtn = nodeItem.shadowRoot.querySelector('.decrease-btn');

    if (!decreaseBtn) {
      addBtn.innerHTML = this.getCartControlsHtml();
    }

    const counterInput: HTMLInputElement = nodeItem.shadowRoot.querySelector('#counter-input');
    const maxCount = item?.maximumsaleunitcount > 0
      ? Math.min(item?.rest_stock, item?.maximumsaleunitcount)
      : item?.rest_stock;
    if (counterInput && item?.itemCount > 0) {
      counterInput.value = item.quantity.toString();
      this.updateButtonStates(nodeItem, maxCount);

    }
  }

  private updateNodeItemInventory(nodeItem: any, item: any): void {
    const inventory = nodeItem.shadowRoot?.querySelector('.search-results-list__item_inventory');

    const iMapPrice = item.Item?.imap_value;

    if (iMapPrice && +item?.offeredperunitprice < +(iMapPrice)) {
      const inStockSection = nodeItem.shadowRoot.querySelector('#inStock-Section');
      inStockSection.innerHTML = '';
      return;
    }
    if (inventory) {
      inventory.innerHTML = `
        <span class="search-results-list__item__inventory_count text-info" itemprop="inventoryLevel">
          Free Store Pickup
        </span>
        <span class="text-primary">${item.rest_stock} in stock</span>`;
    }
  }

  private getCartControlsHtml(): string {
    return `
      <div class="card-button-group" style="height: 1.75rem !important;">
        <button aria-label="Decrease value" class="decrease-btn" id="decrease-btn">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8" />
            </svg>
          </span>
        </button>
        <div class="mat-form-field">
          <input type="number" min="1" max="100" style="height: 32px !important;" class="mat-input-element" value="1" id="counter-input" aria-invalid="false" aria-required="false" disabled>
        </div>
        <button aria-label="Increase value" class="increase-btn" id="increase-btn">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
            </svg>
          </span>
        </button>
      </div>`;
  }


  private updateMatchedSearchResultsForNoStock(searchResultsItems: any[], lessStockItem: any) {
    searchResultsItems.forEach((nodeItem: any) => {
      if (+nodeItem.data.id === +lessStockItem.Item.id) {
        const shadowRoot = nodeItem.shadowRoot;
        const addBtn = shadowRoot?.querySelector('.addBtn');
        const inventory = shadowRoot?.querySelector('.search-results-list__item_inventory');

        if (addBtn) {
          addBtn.removeAttribute('onclick');
          addBtn.innerHTML = this.getAddToCartButtonHtmlForNoStock(+nodeItem.data.id);
        }
        if (inventory) {
          inventory.innerHTML = `
            <span class="search-results-list__item__inventory_count text-primary" itemprop="inventoryLevel">
              Out of stock
            </span>`;
        }
      }
    });
  }


  private getAddToCartButtonHtmlForNoStock(itemId: number) {
    return `<a class="product-button-availability" onclick="storeBox(${itemId})">Check Availability</a>`;
  }



  deleteProductFormCart(item: any, cartDataInfo: any) {
    this.deleteCart(item.customerorderproductlineitem_id, null).subscribe((value: any) => {
      if (value?.is_processed) {
        this.openSnackbar(value.message);
        this.getCartItem();
        return;
      }
      const itemId = +(item.item_id || item.Item.id);
      const posItemQualifier = +(item.positemqualifier || item.Item.positemqualifier);

      const index = cartDataInfo.findIndex((x: any) =>
        +x.item_id === itemId && +x.positemqualifier === posItemQualifier
      );
      if (index !== -1) {
        cartDataInfo.splice(index, 1);
      }
      this.updateSearchResults(item, cartDataInfo);
      this.openSnackbar("A product has been removed from the cart.");
      if (cartDataInfo.length == 0) {
        localStorage.removeItem('customerorder_id');
      }
      this.getCartItem();
      this.auth.getCouponVerify('remove');
    })
  }

  // ++ Move To wishlist
  moveToWishlist(item: any): any {
    this.doFavorite(item, true);
  }

  productRemoveFromCartAfterAddedToWishList(item: any, booking_flag?: boolean) {
    !booking_flag && this.analyticsService.addToWishlist(item);
    let cartDataInfo: any = [];
    let element = this.cartItems;
    cartDataInfo = element.cartDetails;

    this.deleteCart(item.customerorderproductlineitem_id, null).subscribe((value: any) => {
      if (value?.is_processed) {
        this.openSnackbar(value.message);
        this.getCartItem();
        return;
      }
      const index = cartDataInfo.findIndex((x: any) => +x.item_id === +item.item_id && +x.positemqualifier === +item.positemqualifier);
      if (index !== -1) {
        cartDataInfo.splice(index, 1);
      }
      if (cartDataInfo.length == 0) {
        localStorage.removeItem('customerorder_id');
      }
      //   this.getCartItem();
      timer(500).subscribe(() => {
        // this.setCartItems({ 'cartCount': 0, 'cartDetails': [], 'totalDiscount': 0, 'totalOfferAmount': 0, 'totalAmount': 0, 'total_tax': 0 });
        this.getCartItem();
      })
    });
  }


  updateCartData(cartData: any): any {
    this.cartDetails = cartData;
  }
  getLocalCartItems(itemStr?: any, businessUnitId?: number): any {
    let dataArray: any = [];
    itemStr.forEach((item: any) => {
      dataArray.push(item.item_id + '_' + item.positemqualifier + '_' + item.quantity + '_');
    });

    let itemString: any = this.getEncodeWithString(dataArray.join(","));

    const firstArrayMap: any = new Map(itemStr.map(item => [item.item_id, item]));
    const eventStoreId = itemStr?.length && itemStr[0]?.booking_item ? itemStr[0].storeid : null;
    return this.dataApiService.getLocalCartItems(itemString, businessUnitId, eventStoreId).pipe(
      map(data => {
        data[0].data.forEach((item: any) => {
          const correspondingItem = firstArrayMap.get(item.itemdata.Item.id)
          if (correspondingItem) {
            item.enddate = correspondingItem.enddate;
            item.special_instructions = correspondingItem.special_instructions;
          }
        });
        const { booking_id, event_id, ordertype, storeid } = itemStr[0];
        const newData = {
          ...(data[0] || {}),
          ...(booking_id !== undefined ? { booking_id } : {}),
          ...(event_id !== undefined ? { event_id } : {}),
          ...(ordertype !== undefined ? { ordertype } : {}),
          ...(storeid !== undefined ? { storeid } : {})
        };

        return [newData];
      }),
    );
  }

  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  getNavigationURL() {
    return this.dataApiService.getNavigationURL().pipe(
      map(data => {
        return data;
      })
    )
  }


  getEncodeWithString(number: any) {
    let key_option = [
      "FghfdghfhfyghfGHFGHdGHdGHFgh",
      "HKJfgHJgHJGhjg897897897JGhjg",
      "HhkjGhjgUJghj8976GHhjhjhj789k",
      "HJfghjG8968fhjf76kj87HJJghjgh",
      "ouhkjfhDTYghfhtyg89ghlhkvhj76",
      "GhjFGfHGhjg8GHJghj789Ghjg78FH",
      "hjguyHJghjHJGGHJ6hjgUI9tuiKJg",
      "GH89FUKJghjfHJghjFHfgDFyg789g",
      "hkjdfGGHJ78GHghj7gF78Fgkjhjg4",
      "GHJDGHJDsghBHKJlkVG7586DXhj5c",
      "G908g76fgygh786fgughifghIJYH",
      "BNCFGHJcHJ786FGHdghSR78FC786c",
      "ghjVYGvcxUYCG896GHvuyV784Hcu",
      "hVUY5HJG89Gvhjgkj89fKJH756DF",
      "HJ786FGHGHJ789FghkjFH789FKJ89",
      "ugkGH789FGFGHJ786DHJ78fgHJ789F"
    ];
    let rand_index = this.getRandomInt(0, 15);
    let auth_key1 = key_option[rand_index];
    rand_index = this.getRandomInt(0, 15);
    let auth_key2 = key_option[rand_index];
    let message = auth_key1 + number + auth_key2;
    let encription = btoa(message);
    return encription;
  }

  getSearch(url: string): any {
    return this.dataApiService.getSearch(url).pipe(
      map(data => {
        return data;
      })
    )
  }

  addToCart(cartObj: any, customerorderid?: number): any {
    return this.dataApiService.addToCart(cartObj, customerorderid).pipe(
      map(data => {
        return data;
      })
    )
  }

  deleteCart(customerorderlineitemid: number, customerOrderId: number) {
    return this.dataApiService.deleteCart(customerorderlineitemid, customerOrderId).pipe(
      map(data => {
        return data;
      })
    )
  }

  getCustomWishlist(customerid: number): any {
    return this.dataApiService.getCustomWishlist(+customerid).pipe(
      map(data => {
        return data
      })
    )
  }

  myAddressGenerate(userId: number, object?: any): any {
    return this.dataApiService.myAddressGenerate(userId, object).pipe(
      map(data => {
        return data;
      })
    )
  }
  myAddressAction(userId: number, object: any, type: string): any {
    return this.dataApiService.myAddressAction(userId, object, type).pipe(
      map(data => {
        return data;
      })
    )
  }

  getWishList() {
    let customerId: any = this.auth.customer_id;
    this.getCustomWishlist(customerId).subscribe((value: any) => {
      this.auth.setWishList(value);
    });
  }

  myOrderListFetch(myOrderListFetch: number, offset?: number, limit?: number, startDate?: string, endDate?: string, orderState?: any, searchValue?: string) {
    return this.dataApiService.myOrderListFetch(myOrderListFetch, offset, limit, startDate, endDate, orderState, searchValue).pipe(
      map(data => {
        return data;
      })
    )
  }
  getCustomerBillingAddressOrderSummary(customerId: number) {
    return this.dataApiService.getCustomerBillingAddressOrderSummary(customerId).pipe(
      map(data => {
        return data;
      })
    )
  }

  checkCuponValidation(code: string) {
    return this.dataApiService.checkCuponValidation(code).pipe(
      map(data => {
        return data;
      })
    )
  }


  orderPickupAuthorize(obj?: any, customer_id?: number) {
    return this.dataApiService.orderPickupAuthorize(obj, customer_id).pipe(
      map(data => {
        return data;
      })
    )
  }
  stripePayment(stripeObj: any) {
    return this.dataApiService.stripePayment(stripeObj).pipe(
      map(data => {
        return data;
      })
    )
  }
  getStripeCardList() {
    return this.dataApiService.getStripeCardList().pipe(
      map(data => {
        return data;
      })
    )
  }
  saveBillingAddress(data: any, type?: string) {
    return this.dataApiService.saveBillingAddress(data, type).pipe(
      map(data => {
        return data;
      })
    )
  }
  checkPaymentMethod(key: string) {
    return this.dataApiService.checkPaymentMethod(key).pipe(
      map(data => {
        return data;
      })
    )
  }
  stripePaymentAuthorized(obj: any) {
    return this.dataApiService.stripePaymentAuthorized(obj).pipe(
      map(data => {
        return data;
      })
    )
  }
  giftCardPaymentCapture(obj: any) {
    return this.dataApiService.giftCardPaymentCapture(obj).pipe(
      map(data => {
        return data;
      })
    )
  }
  getProductDefaultImage(product, fromProductItem = false, qualifier?: any) {
    if (!product) {
      return null;
    }
    let defaultImage: any = {
      image: '',
      alt_tag: product?.Item?.name
    };

    const itemImages = product.itemimages;

    if (!itemImages || itemImages.length === 0) {
      return defaultImage;
    }

    if (fromProductItem && itemImages[0]?.positemqulifier_id) {
      const filteredItemImages = itemImages.filter(x => x.positemqulifier_id === (qualifier || product.default_qualifier || product?.Item?.default_qualifier));
      if (filteredItemImages.length > 0) {
        const defaultImageArray = filteredItemImages.find(item => item.is_default === 'Yes');
        return defaultImageArray || filteredItemImages[0];
      }
    }

    const defaultImageArray = itemImages.filter(item => item.is_default === 'Yes');
    defaultImage = product.itemimages[0];
    if (defaultImageArray.length > 0) {
      defaultImage = defaultImageArray[0];
      return defaultImage;
    }

    return defaultImage;
  }


  getNotFoundImageUrl(width: any, height: any) {
    return this.utilityService.getLazyImage('', '', false, `${width}x${height}`);
  }

  getNotFoundImage(event: any, width: any, height: any) {
    let defaultImage = this.getNotFoundImageUrl(width, height);
    event.target.src = defaultImage;
  }

  getPrice(item: any) {
    let quantity: number = 1;
    if (item.hasOwnProperty('quantity')) {
      quantity = item.quantity;
    }
    let price: any = 0;
    item.regularpriceData = item.regularpriceData.filter((element: any) => +element.positemqulifier == +item.positemqualifier);
    if (item.regularpriceData.length > 0) {
      price = item.regularpriceData[0].price;
    }
    item.breakpriceData = item.breakpriceData.filter((element: any) => +element.positemqulifier == +item.positemqualifier);
    let breakprice: any = item.breakpriceData.filter((x: any) => x.quantityfrom <= quantity && x.quantityto >= quantity);
    breakprice.sort((a: any, b: any) => a.price - b.price);
    if (breakprice.length > 0) {
      price = breakprice[0].price;
    }
    item.regularPrice = price;

    let offer_list: any = [];
    if (item.hasOwnProperty('offer_list')) {
      let offer_list1: any = item.offer_list.filter((element: any) => !('posqualifier' in element));
      let offer_list2: any = item.offer_list.filter((element: any) => ('posqualifier' in element) && +element.posqualifier === +item.positemqualifier);
      offer_list = [...offer_list1, ...offer_list2];
    }

    /**
     * rewardgroup =>  2 for percentage   rewardgroup => 1 for flat discount  rewardgroup => 3  New offer, rewardlimitamount*
     * */
    offer_list = offer_list.filter((x: any) => +x.minimumquantity <= +quantity && (+x.maximumquantity >= +quantity || +x.maximumquantity == 0) && +x.subtotalminimumamount <= +price * quantity && (+x.subtotalmaximumamount >= +price * quantity || +x.subtotalmaximumamount == 0) && ((+x.rewardgroup == 1 && +price > +x.rewardlimitamount) || +x.rewardgroup == 2 || (+x.rewardgroup == 3 && +price > +x.rewardlimitamount)));
    let oferPriceArray: any = [];
    offer_list.forEach((element: any, index: any) => {
      let offerPrice: any = 0;
      if (element.rewardgroup === 1) {
        offerPrice = price - element.rewardlimitamount;
      }
      else if (element.rewardgroup === 2) {
        offerPrice = price - price * element.rewardlimitamount / 100;
      }
      else if (element.rewardgroup === 3) {
        offerPrice = element.rewardlimitamount;
      }
      offerPrice = offerPrice ? offerPrice.toFixed(2) : 0;
      oferPriceArray.push(offerPrice);
      offer_list[index].offerPrice = +offerPrice;
    });

    offer_list.sort((a: any, b: any) => a.offerPrice - b.offerPrice);

    oferPriceArray.sort((a: any, b: any) => a - b);

    /*console.log(offer_list);
    console.log(oferPriceArray);*/

    let offerprice: any = oferPriceArray.length > 0 ? oferPriceArray[0] : 0;

    item.offerPrice = +offerprice;

    item.discountPercentage = +(item.offerPrice > 0 ? ((item.regularPrice - item.offerPrice) * 100 / item.regularPrice).toFixed(2) : 0);

    item.rewarddescription = offer_list.length > 0 ? offer_list[0]?.rewarddescription : '';

    item.price = +(item.offerPrice > 0 ? item.offerPrice : item.regularPrice);

  }


  /*getValidOfferList(offer_list, price, quantity){

  }*/

  countLines(domId: any) {
    let divHeight = 0;
    let el: any = document.querySelector('#' + domId);
    if (el) {
      divHeight = el.offsetHeight
    }
    return divHeight;
  }

  increaseCount(item: any, bookingData: any, cartData?: any) {
    let addToCartBtnStatus: boolean = false;
    if (!this.cartService.getItemCheck(item.Item.id, item)) {
      addToCartBtnStatus = true;
    }

    item.cartBtn = false;
    item.quantity = +item.quantity + 1;
    item.itemCount = +item.quantity;
    if (cartData && Object.keys(cartData).length) {
      cartData.cartBtn = false;
      cartData.quantity = +cartData.quantity + 1;
      cartData.itemCount = +cartData.quantity;
    }
    // console.log(item)
    if (!addToCartBtnStatus) {
      this.addCart(item, 'add', false, bookingData);
    }
    this.getPrice(cartData || item);
    this.updateSearchResultsForNoStock(item);
  }
  checkNumberValidation(event: any) {
    var key = event.keyCode;
    if (key === 43 || key === 45 || key === 46) {
      event.preventDefault();
    }
  }

  async manageCount(cur: any, item: any, from?: string, bookingData?: any) {
    const isValidQuantity = !isNaN(+cur) && cur !== null && +cur !== 0 && cur !== '';
    const { ignore_stock, stock } = isValidQuantity ? await this.stockAvailCheckerAsPerUOM(item) : null;

    if (isValidQuantity && (ignore_stock == true || (stock && stock >= +cur))) {
      const addToCartBtnStatus: boolean = !this.cartService.getItemCheck(item.Item.id, item);

      item.cartBtn = false;
      item.quantity = +cur;
      item.itemCount = +item.quantity;

      if (!addToCartBtnStatus) {
        this.addCart(item, 'add', false, bookingData);
      }

      this.getPrice(item);
    } else if (cur == null || +cur == 0 || cur == '') {
      const addToCartBtnStatus: boolean = !this.cartService.getItemCheck(item.Item.id, item);

      item.cartBtn = false;
      item.quantity = 1;
      item.itemCount = +item.quantity;

      if (!addToCartBtnStatus) {
        this.addCart(item, 'add', false, bookingData);
      }

      this.getPrice(item);
    }
    this.updateSearchResultsForNoStock(item);
  }

  stockAvailCheckerAsPerUOM(item: any) {
    if (item.Item.positemqulifier_id == undefined && item?.positemqualifier) {
      item.Item.positemqulifier_id = item.positemqualifier;
    }
    return new Promise<any>((resolve: any, reject: any) => {
      let newArr: any = '';
      if (item) {
        newArr = 'items=' + item.Item.id + ',' + item.Item.positemqulifier_id;
      }
      if (newArr !== '') {
        this.checkItemsInStock(newArr).subscribe((value: any) => {
          if (value && value.stock && value.stock.length > 0) {
            if (value.stock[0].item_id == item.Item.id) {
              resolve(value.stock[0]);
            }
          }
        })
      }
    })
  }

  decreaseCount(item: any, bookingData: any, cartData?: any) {
    let addToCartBtnStatus: boolean = false;
    if (!this.cartService.getItemCheck(item.Item.id, item)) {
      addToCartBtnStatus = true;
    }

    item.itemCount = item.quantity - 1;
    if (cartData && Object.keys(cartData).length) {
      cartData.cartBtn = false;
      cartData.quantity = +cartData.quantity - 1;
      cartData.itemCount = +cartData.quantity;
    }
    if (item.itemCount === 0) {
      item.cartBtn = false;
    }
    if (item.quantity > 0) {
      item.quantity = +item.quantity - 1;
    }
    if (!addToCartBtnStatus) {
      this.addCart(item, 'remove', false, bookingData);
    }
    this.getPrice(cartData || item);
    this.updateSearchResultsForNoStock(item);
  }

  updateCart(item: any, bookingData?: any) {
    this.addCart(item, 'add', false, bookingData);
  }

  updateButtonStates(element: HTMLElement, stock: number) {
    const decreaseBtn: HTMLElement = element.shadowRoot.querySelector('#decrease-btn');
    const increaseBtn: HTMLElement = element.shadowRoot.querySelector('#increase-btn');
    const counterInput: HTMLInputElement = element.shadowRoot.querySelector('#counter-input');

    let currentValue = parseInt(counterInput?.value);

    if (currentValue >= stock) {
      increaseBtn?.classList.add('active');
      increaseBtn?.setAttribute('disabled', 'disabled');
    } else {
      increaseBtn.classList.remove('active');
      increaseBtn.removeAttribute('disabled');
    }

    if (currentValue <= 1) {
      decreaseBtn?.classList.remove('active');
    } else {
      decreaseBtn?.classList.remove('active');
      decreaseBtn?.removeAttribute('disabled');
    }
  }

  moveMultipleProductToWishList(items: any, booking_flag?: boolean) {
    items.forEach((item: any) => {
      this.productRemoveFromCartAfterAddedToWishList(item, booking_flag);

      if (!booking_flag && !item.wishlist) {
        this.wishListData({ item_ids: [`${item.Item.id},${item.Item.positemqulifier_id}`] }, false)
          .subscribe(
            (value: any) => {
              if (value) {
                this.getWishList();
                this.openSnackbar("Product has been moved from cart to your wishlist");
              }
            },
            (err: any) => {
              this.openSnackbar(err);
            }
          );
      }
    });
  }


  doFavorite(item: any, removeFromCart?: boolean) {
    if (item) {
      this.checkLogInState();
      let arr: any = []
      arr.push(`${item.Item.id},${item.positemqualifier}`);
      let obj: any = { item_ids: arr }
      if (item.wishlist && removeFromCart !== true) {
        this.wishListData(item.Item.id, item.wishlist).subscribe((value: any) => {
          if (value) {
            item.wishlist = false;
            this.openSnackbar("Product has been removed from your wishlist");
            this.getWishList();
          }
        })
      } else {
        if (!this.isLoggedin) {
          const dialogRef = this.dialog.open(LoginComponent, {
            panelClass: ['pop-up', 'md-pop', 'login-pop']
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.wishListData(obj, item.wishlist).subscribe((value: any) => {
                if (value) {
                  item.wishlist = true;

                  this.getWishList();
                  if (removeFromCart) {
                    this.productRemoveFromCartAfterAddedToWishList(item);
                    this.openSnackbar("Product has been moved from cart to your wishlist");
                  }
                  else {
                    this.openSnackbar("Product has been added to your wishlist");
                  }
                }
              },
                (err: any) => {
                  this.openSnackbar(err);
                })
            }
          });
        } else {
          this.wishListData(obj, item.wishlist).subscribe((value: any) => {
            if (value) {
              item.wishlist = true;
              this.getWishList();
              if (removeFromCart) {
                this.dataApiService.wishListData(obj, false).subscribe((val: any) => {
                  this.productRemoveFromCartAfterAddedToWishList(item);
                  timer(800).subscribe(() => this.openSnackbar("Product has been moved from cart to your wishlist"));
                  // this.openSnackbar("Product has been moved from cart to your wishlist");
                  this.auth.getCouponVerify('remove');
                })
                // this.productRemoveFromCartAfterAddedToWishList(item);
                // // this.openSnackbar("Product has been moved from cart to your wishlist");
                // this.auth.getCouponVerify('remove');
              }
              else {
                this.openSnackbar("Product has been added to your wishlist");
              }
            }
          },
            (err: any) => {

              this.openSnackbar(err.message);
            })
        }
      }
    }
  }



  /* this.wishListData(obj, item.wishlist).subscribe((value: any) => {
     if (value) {
       item.wishlist = true;

       this.getWishList();
       if(removeFromCart){
         this.productRemoveFromCartAfterAddedToWishList(item);
         this.openSnackbar("Product has been moved from cart to your wishlist");
       }
       else{
         this.openSnackbar("Product has been added to your wishlist");
       }
     }*/


  getProductDetails(product: any, qualifierId_?: number, eventStoreId?: any) {
    this.setQualifiers(product);
    this.setWishlistStatus(product);
    this.setRatingAndReviewCount(product);
    this.setPosItemQualifiers(product, eventStoreId);
    this.setSellingRules(product, eventStoreId);
    this.setCollectionWisePrice(product, eventStoreId);
    this.setUOMConversion(product, eventStoreId);

    return product;
  }

  private setQualifiers(product: any) {
    let qualifierID: number;
    if (product.Item.positemqualifier_id !== undefined) {
      product.Item.id = +product.Item.id;
      qualifierID = product.Item.positemqualifier_id;
      product.positemqualifier = qualifierID;
      product.wishlist = false;
    }
  }

  private setWishlistStatus(product: any) {
    this.auth?.wishlistData.subscribe((wishlist: any) => {
      if (wishlist != null) {
        if (wishlist.length > 0) {
          let wistlistproduct = wishlist.filter((item: any) => +item.id === +product.Item.id);
          if (wistlistproduct.length > 0) {
            product.wishlist = true;
          }
        }
      }
    });
  }

  private setRatingAndReviewCount(product: any) {
    product.rating = 0;
    product.review_count = 0;
    if (product.Item.review_rating) {
      product.rating = product.Item.review_rating.toFixed(0);
    }
    if (product.Item.review_count) {
      product.review_count = product.Item.review_count;
    }
  }
  /**
   * setPosItemQualifiers() method started
   * @param product 
   * @param eventStoreId 
   */
  private setPosItemQualifiers(product: any, eventStoreId?: any) {
    const positemqualifiers = this.getActivePosItemQualifiers(product);

    const {
      allQualifierWisePriceArray,
      regularpriceData,
      breakpriceData,
      posidentity
    } = this.prepareQualifierData(product, positemqualifiers, eventStoreId);

    product.allQualifierWisePriceArray = allQualifierWisePriceArray;
    product.regularpriceData = regularpriceData;
    product.breakpriceData = breakpriceData;

    const defaultQualifier = this.getDefaultQualifier(positemqualifiers);

    product.special_instructions = defaultQualifier?.special_instructions || '';
    product.enddate = defaultQualifier?.enddate || '';

    product.positemqualifierName = product.positemqualifierName || defaultQualifier?.positemqulifiername || '';
    product.positemqualifier = product.positemqualifier || defaultQualifier?.positemqulifier || 0;

    const positemqulifierPosidentity = this.getPositemqulifierPosidentity(posidentity, product.positemqualifier);

    product.actual_stock = positemqulifierPosidentity?.stock_qty || 0;
    product.maximumsaleunitcount = positemqulifierPosidentity?.maximumsaleunitcount || 0;

    this.updateProductQuantityFromCart(product);

    if (!product.hasOwnProperty('quantity')) {
      product.itemCount = 1;
      product.quantity = 1;
    }
  }

  private getActivePosItemQualifiers(product: any): any[] {
    return product.hasOwnProperty('positemqualifiers')
      ? product.positemqualifiers.filter((element: any) => element.status === 'Active')
      : [];
  }

  private prepareQualifierData(product: any, positemqualifiers: any[], eventStoreId?: any): any {
    let allQualifierWisePriceArray: any = [];
    let regularpriceData: any = [];
    let breakpriceData: any = [];
    let posidentity: any = [];
    let actual_stock: number = 0;
    let maximumsaleunitcount: number = 0;

    positemqualifiers.forEach((element: any) => {
      const storeId = +eventStoreId || +localStorage.getItem('storeId');

      regularpriceData = regularpriceData.concat(this.getPriceArray(product.item_regular_price, storeId, element.positemqulifier));
      breakpriceData = breakpriceData.concat(this.getPriceArray(product.item_break_price, storeId, element.positemqulifier, true));
      posidentity = posidentity.concat(this.getStockArray(product.posidentity, storeId, element.positemqulifier));
    });

    allQualifierWisePriceArray.sort((a: any, b: any) => a - b);

    return { allQualifierWisePriceArray, regularpriceData, breakpriceData, posidentity, actual_stock, maximumsaleunitcount };
  }

  private getPriceArray(priceArray: any[], storeId: number, positemqulifier: number, positivePrice: boolean = false): any[] {
    return priceArray ? priceArray.filter((value: any) => +value.store === storeId && +value.positemqulifier === positemqulifier && (positivePrice ? +value.price > 0 : true)) : [];
  }

  private getStockArray(stockArray: any[], storeId: number, positemqulifier: number): any[] {
    return stockArray ? stockArray.filter((value: any) => +value.store_id === storeId && +value.positemqulifier_id === positemqulifier) : [];
  }

  private getDefaultQualifier(positemqualifiers: any[]): any {
    const defaultQualifier = positemqualifiers.find((element: any) => element.isdefault === true);
    return defaultQualifier || positemqualifiers[0] || {};
  }

  private getPositemqulifierPosidentity(posidentity: any[], positemqualifier: number): any {
    return posidentity.find((element: any) => +element.positemqulifier_id === +positemqualifier) || {};
  }

  private updateProductQuantityFromCart(product: any): void {
    if (!('isAddonOf' in product) || product?.isAddonOf === null) {
      const cartDataInfo: any = this.cartService.getCartItemArray();
      const cartDataDetails: any = cartDataInfo.filter((x: any) => +x.item_id === +product.Item.id && +x.positemqualifier === +product.positemqualifier && !('isAddonOf' in x));

      if (cartDataDetails.length > 0) {
        product.itemCount = +cartDataDetails[0].quantity;
        product.quantity = +cartDataDetails[0].quantity;
      }
    }
  }
  // setPosItemQualifiers() method ends



  private setPriceData(product: any, collectionWisePriceArray?: any) {
    this.getPrice(product);
    collectionWisePriceArray.push({ price: product.price, regularPrice: product.regularPrice });

    collectionWisePriceArray.sort((a: any, b: any) => (a.regularPrice + a.price) - (b.regularPrice + b.price));
    product.collectionWisePriceArray = collectionWisePriceArray;
  }


  private setSellingRules(product: any, eventStoreId?: any) {
    let inStoreOnly = false;
    let notToShowOnline = false;
    let notToShowInventory = false;
    let notToShowBookingInventory = false;
    let booking = false;
    let special_order_only = false;

    if (!product.hasOwnProperty('posidentity')) {
      return; // No further processing needed
    }

    const storeIdToCheck = eventStoreId || +localStorage.getItem('storeId');

    const sellingRule = product.posidentity.find((item: any) =>
      +item.store_id === +storeIdToCheck && +item.positemqulifier_id === +product.positemqualifier
    );

    if (!sellingRule) {
      return; // No matching selling rule found
    }

    inStoreOnly = sellingRule.in_store_only === true;
    notToShowOnline = sellingRule.not_to_show_online === true;
    notToShowInventory = sellingRule.not_to_show_inventory === true;
    notToShowBookingInventory = sellingRule.do_not_show_booking_inventory === true;
    booking = sellingRule.booking === true;
    special_order_only = sellingRule.special_order_only === true;

    product.inStoreOnly = inStoreOnly;
    product.notToShowOnline = notToShowOnline;
    product.notToShowInventory = notToShowInventory;
    product.notToShowBookingInventory = notToShowBookingInventory;
    product.booking = booking;
    product.special_order_only = special_order_only;
  }

  /**
   * setCollectionWisePrice()
   * @param product 
   * @param eventStoreId 
   */
  private setCollectionWisePrice(product: any, eventStoreId?: any) {
    if ('itemcollections' in product) {
      const collectionWisePriceArray: any = [];

      product.itemcollections = this.filterActiveItemCollections(product.itemcollections);

      product.itemcollections.forEach((element: any) => {
        const { regularpriceData, breakpriceData } = this.processItemCollection(element, eventStoreId);

        element.regularpriceData = regularpriceData;
        element.breakpriceData = breakpriceData;
        this.getPrice(element);

        collectionWisePriceArray.push({ price: element.price, regularPrice: element.regularPrice });
      });

      this.setPriceData(product, collectionWisePriceArray);
    }
  }

  private filterActiveItemCollections(itemcollections: any[]): any[] {
    return itemcollections.filter((o: any) => !o.hasOwnProperty('status') || (o.hasOwnProperty('status') && o.status.toLowerCase() == 'active'));
  }

  private processItemCollection(element: any, eventStoreId?: any): any {
    let regularpriceData: any = [];
    let breakpriceData: any = [];

    if (element.hasOwnProperty('positemqualifiers')) {
      element.positemqualifier = element.positemqualifiers[0];
    }

    if (element.hasOwnProperty('item_regular_price')) {
      const priceArray = this.filterItemRegularPrice(element.item_regular_price, eventStoreId);
      regularpriceData = priceArray;
      if (priceArray.length > 0) {
        element.positemqualifier = priceArray[0].positemqulifier;
      }
    }

    if (element.hasOwnProperty('item_break_price')) {
      const priceArray1 = this.filterItemBreakPrice(element.item_break_price, eventStoreId);
      breakpriceData = priceArray1;
    }

    return { positemqualifier: element.positemqualifier, regularpriceData, breakpriceData };
  }

  private filterItemRegularPrice(itemRegularPrice: any[], eventStoreId?: any): any[] {
    return itemRegularPrice.filter((o: any) => +o.store === +(eventStoreId || localStorage.getItem('storeId')));
  }

  private filterItemBreakPrice(itemBreakPrice: any[], eventStoreId?: any): any[] {
    return itemBreakPrice.filter((value: any) => +value.store === +(eventStoreId || localStorage.getItem('storeId')) && +value.quantityfrom === 1 && +value.price > 0);
  }


  // setCollectionWisePrice() ends here.

  private setUOMConversion(product: any, eventStoreId?: any) {
    let defaultStock: number = 0;
    if (product && product.hasOwnProperty('store_data')) {
      Object.entries(product.store_data).forEach(([key, value]) => {
        let itemValue: any = value
        if (itemValue.store_id == (eventStoreId ?? (eventStoreId || +localStorage.getItem('storeId')))) {
          defaultStock = itemValue.positemqulifier_default.stock_qty;
        }
      });
      product.defaultStock = defaultStock;
    }
  }


  get cartData() {
    return this.cartService.getCartItemArray();
  }

  /**
   * Move To Cart From Wishlist
   */
  moveToCartFromWishList(item: any) {
    // console.log(item);


    let itemPresentIncart: boolean = false;
    if (this.cartData.length) {
      this.cartData.forEach((a: any) => {
        if (a.item_id == item.Item.id) itemPresentIncart = true;
      })
    }

    if (!itemPresentIncart) {
      let cartItems: any = this.cartService.getCartItemArray();
      let alreadyExistsArray: any = cartItems.filter((element: any) => +element.Item.id === +item.Item.id);
      //this.doFavorite(item);
      let itemExist: boolean = false;
      alreadyExistsArray.filter((b: any) => {
        if (+b.Item.id == +item.Item.id) itemExist = true;
      })
      item.quantity = alreadyExistsArray.length > 0 ? item.quantity + 1 : 1;
      this.addCart(item, 'add', true);
    }
    else this.openSnackbar("This Product is available in Cart in another unit, You can't add a single product in more than one unit!");


  }
  buyItAgain(item: any, bookingData?: any) {
    let cartItems: any = this.cartService.getCartItemArray();
    let alreadyExistsArray: any = cartItems.filter((element: any) => +element.Item.id === +item.Item.id);
    //this.doFavorite(item);
    // let itemExist: boolean = false;
    // alreadyExistsArray.filter((b: any) => {
    //   if (+b.Item.id == +item.Item.id) itemExist = true;
    // })
    // // item.quantity = alreadyExistsArray.length > 0 ? item.quantity + 1 : 1;
    // if(itemExist) this.openSnackbar("This Product is already available in Cart!");
    // else this.addCart(item, 'add', false);
    if (alreadyExistsArray.length) {
      alreadyExistsArray.filter((b: any) => {
        if (+b.Item.id == +item.Item.id) {
          if (b.Item.positemqulifier_id == item.positemqualifier_id) {
            this.router.navigate(['/cart']);
          } else {
            this.openSnackbar("This Product is available in Cart in another unit, You can't add a single product in more than one unit!");
          }
        }
      })
    } else {
      this.addCart(item, 'add', false, bookingData);
      this.router.navigate(['/cart']);
    }
  }

  buyNow(itemDetails: any, bookingData: any) {
    let cartItems: any = this.cartService.getCartItemArray();
    let alreadyExistsArray: any = cartItems.filter((element: any) => +element.Item.id === +itemDetails.Item.id);
    //this.doFavorite(item);
    let itemExist: boolean = false;
    if (alreadyExistsArray.length) {
      alreadyExistsArray.filter((b: any) => {
        if (+b.Item.id == +itemDetails.Item.id) {
          if (+b.Item.positemqulifier_id == +itemDetails.Item.positemqualifier_id) {
            this.router.navigate(['/cart']);
          } else {
            this.openSnackbar("This Product is available in Cart in another unit, You can't add a single product in more than one unit!");
          }
        }
      })
    } else {
      this.addCart(itemDetails, 'add', false, bookingData);
      this.router.navigate(['/cart']);
    }
  }

  claimRebate(url: any, type: string, obj: any, urlType: string): any {
    return this.dataApiService.claimRebate(url, type, obj, urlType).pipe(
      map(data => {
        return data;
      })
    )
  }
  getActiveEbateUrl(): any {
    return this.dataApiService.getActiveEbateUrl().pipe(
      map(data => {
        return data;
      })
    )
  }
  getRebateUrlVersion(): any {
    return this.dataApiService.getRebateUrlVersion().pipe(
      map(data => {
        return data;
      })
    )
  }
  getRebateList(url: any, type: string, obj: any): any {
    return this.dataApiService.getRebateList(url, type, obj).pipe(
      map(data => {
        return data;
      })
    )
  }

  getSeoDetails(cmsSlugKey: string, cmsSlug: string, storeId?: string): any {
    return this.dataApiService.getSeoDetails(cmsSlugKey, cmsSlug, storeId).pipe(
      map(data => {
        return data;
      })
    )
  }


  getSitemapDetails(pageName: string, slugData?: any, SitemapObj?: any): any {
    return this.dataApiService.getSitemapDetails(pageName, slugData, SitemapObj).pipe(
      map(data => {
        return data;
      })
    )
  }

  createxmlsitemap(url: string, param?: any): any {
    return this.dataApiService.createxmlsitemap(url, param).pipe(
      map(data => {
        return data;
      })
    )
  }

  getExistSitemap(url: string, filename: string): any {
    return this.dataApiService.getExistSitemap(url, filename).pipe(
      map(data => {
        return data;
      })
    )
  }


  getAllStoreStocks(productId: any, qualifierId: any) {
    return this.dataApiService.getStockChecksForAllStores(productId, qualifierId).pipe(
      map(data => {
        return data;
      })
    )
  }

  getStoreWiseStock(itemDetails: any) {
    this.getAllStoreStocks(itemDetails.Item.id, itemDetails.positemqualifier).subscribe((data: any) => {
      let stockArray = [];
      if (data.hasOwnProperty('stock')) {
        if (data.stock.length > 0) {
          stockArray = data.stock.filter((value: any, i: any) => {
            /**changed for show store stock only for default qc */
            // if (value.stock_qty > 0 && value.positemqulifier_id == itemDetails.positemqualifier) {
            if (value.stock > 0) {
              return value;
            }
          });
        }
      }

      let stroreWiseStock: any = [];
      stockArray.forEach((element: any) => {
        let actual_stock: any = element.stock ?? 0;
        stroreWiseStock.push({ 'actual_stock': actual_stock, 'positemqulifier': element.positemqulifier, 'store': element.store_id, });
      });

      stroreWiseStock.filter((value: any, index: any) => stroreWiseStock.indexOf(value) === index);
      this.auth.setStoreWiseProductStock(stroreWiseStock);
    })

    // let stockArray = [];
    // if (itemDetails.hasOwnProperty('posidentity')) {
    //   if (itemDetails.posidentity.length > 0) {
    //     stockArray = itemDetails.posidentity.filter((value: any, i: any) => {
    //       /**changed for show store stock only for default qc */
    //       // if (value.stock_qty > 0 && value.positemqulifier_id == itemDetails.positemqualifier) {
    //       if (value.stock_qty > 0) {
    //         return value;
    //       }
    //     });
    //   }
    // }

    // let stroreWiseStock: any = [];
    // stockArray.forEach((element: any) => {
    //   let actual_stock: any = element.not_to_show_online !== true ? element.stock_qty : 0;
    //   stroreWiseStock.push({ 'actual_stock': actual_stock, 'positemqulifier': element.positemqulifier_id, 'store': element.store_id, });
    // });

    // stroreWiseStock = stroreWiseStock.filter((li: any, idx: any, self: any) => self.map((itm: any) => itm.actual_stock + itm.positemqulifier + itm.store).indexOf(li.actual_stock + li.positemqulifier + li.store) == idx);


    /*fix code of 1552*/
    // stroreWiseStock = stroreWiseStock.filter((li: any, idx: any, self: any) => self.map((itm: any) => itm.actual_stock + itm.positemqulifier + itm.store).indexOf((li.actual_stock + li.positemqulifier + li.store), idx) == idx);

    // console.log(stroreWiseStock);


    /*debug code of 1552*/
    // stroreWiseStock = stroreWiseStock.filter((li: any, idx: any, self: any) => {
    //   const mapData = self.map(
    //     (itm: any) => itm.actual_stock + itm.positemqulifier + itm.store
    //   );
    //   console.log(mapData, idx, li.actual_stock + li.positemqulifier + li.store);
    //   const indexResult = mapData.indexOf((li.actual_stock + li.positemqulifier + li.store))
    //   console.log(indexResult);

    //   const r = indexResult == idx;
    //   console.log(r);
    //   return r;
    // });

    // stroreWiseStock.filter((value: any, index: any) => stroreWiseStock.indexOf(value) === index);
    // this.auth.setStoreWiseProductStock(stroreWiseStock);
  }


  getTodayDate() {
    const currentDate = new Date();
    let fullyear = currentDate.getFullYear();
    let month: any = currentDate.getMonth() + 1;
    month = month > 9 ? month : '0' + month;
    let date: any = currentDate.getDate();
    date = date > 9 ? date : '0' + date;
    let todayDate = `${fullyear}-${month}-${date}`;
    return todayDate;
  }

  checkItemsInStock(itemsArr: any, booking_store?: number) {
    return this.dataApiService.checkItemsInStock(itemsArr, booking_store).pipe(
      map(data => {
        return data;
      })
    )
  }

  getGlobalScript() {
    return this.dataApiService.getGlobalScript().pipe(
      map(data => {
        return data;
      })
    )
  }
  storeChecker(cartData: any, from?: string) {
    let savedStoreId = localStorage.getItem('storeId');
    const array = this.cartService.getAllsoreData();
    const savedStoreArray = array.length > 0 ? array.filter((a: any) => +a.id == +cartData.store) : [];
    if (cartData && cartData.store == savedStoreId) {
      // console.log('passed');
    } else {
      // console.log('failed');
      if (savedStoreArray.length > 0 && !this.cartService.storeChangedManually) this.shopThisStore(savedStoreArray);
    }
  }
  shopThisStore(item: any) {
    const currentStoreId = localStorage.getItem('storeId');
    const isBookingCart: boolean = localStorage.getItem('booking_flag') === 'true' ? true : false;
    if (!isBookingCart && +item[0].id !== +currentStoreId) {
      let cartProducts: any = this.getCartItemArray;
      const currentStore = localStorage.getItem('storeName');
      // const currentStoreId = localStorage.getItem('storeId');
      if (cartProducts) {
        const dialogRef = this.dialog.open(AlertModalComponent, {
          panelClass: ['pop-up', 'sm-pop'],
          data: {
            displayTitle: 'Shopping Location Changed!', displayMsg: `You already have items in your cart for ${item[0].storename} store. Do you want to abandon your cart and shop at ${currentStore}? Note: Your cart will be reset.`, confirmButtonText: 'Reset Cart'
          }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          this.cartService.callApiOnce = false;
          // this.cartService.setCart(null);
          if (result === "success") {
            const array = this.cartService.getAllsoreData();
            const savedStoreArray = array.length > 0 ? array.filter((a: any) => a.id == currentStoreId) : [];
            if (savedStoreArray.length) this.changeStore(savedStoreArray[0]);
          } else {
            localStorage.setItem('storeId', item[0].id);
            localStorage.setItem('storenumber', item[0].storenumber);
            localStorage.setItem('businessUnitId', item[0].businessunit);
            localStorage.setItem('storeName', item[0].storename);
            this.openSnackbar(`Your Location is set to ${item[0].storename} store.`);
            if (this.router.url.includes('/checkout')) {
              this.router.navigate(['/cart']);
            }
          }
        });
      }
    }
  }
  changeStore(item: any) {
    /**
     * Remove cart item after store change
     */
    let cartProducts: any;
    const productsFromCartService = this.cartService.getcartDataNew();
    if (productsFromCartService) {
      cartProducts = productsFromCartService.data;
    } else {
      cartProducts = this.cartService.getCartItemArray();
    }
    if (!this.isLoggedin) {
      let cartDetailsStorage: any = [];
      localStorage.setItem('cartDetailsStorage', JSON.stringify(cartDetailsStorage));
      this.getCartItem();
    }
    else {
      if (cartProducts.length > 0) {
        cartProducts.forEach((item: any) => {
          this.productRemoveFromCartAfterAddedToWishList(item);
        });
      }
    }

    localStorage.setItem('storeId', item.id);
    localStorage.setItem('storenumber', item.storenumber);
    localStorage.setItem('businessUnitId', item.businessunit);
    localStorage.setItem('storeName', item.storename);
    if (this.router.url.includes('/checkout')) {
      this.router.navigate(['/cart']);
    };
  }

  get getCartItemArray() {
    return this.cartService.getCartItemArray();
  }


  getOrderHistoryList(requestData: any) {
    return this.dataApiService.getOrderHistoryList(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }

  getorderHistoryDetails(requestData: any) {
    return this.dataApiService.getorderHistoryDetails(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }

  getorderPImage(requestData: any) {
    return this.dataApiService.getorderPImage(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }
  saveGiftcards(requestData: any) {
    return this.dataApiService.saveGiftcards(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }
  getGiftcards(requestData: any) {
    return this.dataApiService.getGiftcards(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }
  deleteGiftcard(requestData: any) {
    return this.dataApiService.deleteGiftcard(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }
  saveGiftcardAddress(requestData: any) {
    return this.dataApiService.saveGiftcardAddress(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }
  getGiftcardOrders(requestData: any) {
    return this.dataApiService.getGiftcardOrders(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }
  deleteCard(requestData: any) {
    return this.dataApiService.deleteCard(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }

  getSliderDataByIds(requesIds: any): any {
    return this.dataApiService.getSliderDataByIds(requesIds).pipe(
      map(data => {
        return data;
      }),
    );
  }
  changeCartStatus(requestData: any) {
    return this.dataApiService.changeCartStatus(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }
  restrictCartDuringPayment(requestData: any) {
    return this.dataApiService.restrictCartDuringPayment(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }

  getImageData(requestData: any): Observable<any> {
    return this.dataApiService.getImageData(requestData);
  }
  getBookingLists(id?: number, offset?: number, limit?: number) {
    return this.dataApiService.getBookingLists(id, offset, limit).pipe(
      map(data => {
        return data;
      })
    )
  }
  getCurrentOrderPickupWindow(eventId: number, pickupWindowId: number) {
    return this.dataApiService.getCurrentOrderPickupWindow(eventId, pickupWindowId).pipe(
      map(data => {
        return data;
      })
    )
  }
  getPickupWindows(event_id: number) {
    return this.dataApiService.getPickupWindows(event_id).pipe(
      map(data => {
        return data;
      })
    )
  }
  getOrderStatus() {
    return this.dataApiService.getOrderStatus().pipe(
      map((res: any) => {
        return res?.data;
      })
    )
  }
  getNotificationEmailState() {
    return this.dataApiService.getEmailState().pipe(
      map((res: any) => {
        return res;
      })
    )
  }
  putNotificationEmailState(custId: number, payload: any) {
    return this.dataApiService.putEmailState(custId, payload).pipe(
      map((res: any) => {
        return res;
      })
    )
  }
  getRebateAllList(url: any): any {
    return this.dataApiService.getRebateAllList(url).pipe(
      map(data => {
        return data;
      })
    )
  }

  fetchJsonldData(itemSlug: string): any {
    return this.dataApiService.fetchJsonldData(itemSlug).pipe(
      map(data => {
        return data;
      })
    )
  }

  itemPromotionHandler(requestData: any) {
    return this.dataApiService.itemPromotionHandler(requestData).pipe(
      map(data => {
        return data;
      })
    )
  }
  guestUserCreation(payload: any): any {
    return this.dataApiService.guestUserCreation(payload).pipe(
      map(data => {
        return data;
      })
    )
  }

  storeBox() {
    const element = document.getElementById("Store-Box");
    element.classList.toggle("active");
  }

  guestUserOrderData(uuid: string, orderID: number): any {
    return this.dataApiService.guestUserOrderData(uuid, orderID).pipe(
      map(data => {
        return data;
      })
    )
  }
  sendPromoUpdates(payload: any): any {
    return this.dataApiService.sendPromoUpdates(payload).pipe(
      map(data => {
        return data;
      })
    )
  }
  getBookingSms(bookingId: number, eventId: number): any {
    return this.dataApiService.getBookingSms(bookingId, eventId).pipe(
      map(data => {
        return data;
      })
    )
  }
  getMultiSliderItem(itemIds: string): any {
    return this.dataApiService.getMultiSliderItem(itemIds).pipe(
      map((data:any) => {
        return data?.data;
      })
    )
  }
}
